<template>
  <div class="relative flex flex-col gap-y-10 md:gap-y-14 lg:gap-y-16">
    <Transition name="fade" mode="out-in">
      <InitialBackdrop v-if="'initial' in route.query"></InitialBackdrop>
      <template v-else>
        <div>
          <client-only>
            <template #fallback>
              <SkeletonsLanding></SkeletonsLanding>
            </template>
            <PageBuilder
              v-if="layout && !isLoading"
              :layout="layout"
            ></PageBuilder>
          </client-only>
        </div>
      </template>
    </Transition>
  </div>
</template>

<script setup>
const st = ref();
const { t } = useI18n();
const runtimeConfig = useRuntimeConfig();
useSeoMeta({
  title: () => `${t("kids_main_category_page_title")}`,
  ogTitle: () =>
    `${t("kids_main_category_page_title")} | ${
      runtimeConfig.public.mdaApplicationName
    }`,
  description: () => `${t("kids_main_category_page_description")}`,
  ogDescription: () => `${t("kids_main_category_page_description")}`,
});

const route = useRoute();

const { data: layout, pending: isLoading } = await useAsyncData(
  "layout",
  async () => {
    return await fetchLayout("/api/biz/config/v1/carousels", { page: "kids" });
  },
  {
    server: false,
  }
);

if ("initial" in route.query) {
  st.value = setTimeout(() => {
    st.value && clearTimeout(st.value);
    navigateTo({
      path: route.path,
      replace: true,
    });
  }, 3000);
}

onBeforeMount(async () => {
  await useFmaSdk().logPageNav(usePageId().KIDS__HOME);
});

onMounted(async () => {
  await useFmaSdk().loaded();
});

onBeforeUnmount(() => {
  st.value && clearTimeout(st.value);
  useFmaSdk().terminate();
});
</script>

<script>
export default { name: "BrowseCategory" };
</script>
